import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import analysisCard from "../ToolsCard.vue";
import moment from "moment";
import Button from "@/components/button";
import Input from "@/components/input";
import Table from "@/components/table";
import Tab from "@/components/tab";
import NeoTabsSwitch from "@/components/tabs-switch";
import Loader from "@/components/loader";
import DropdownAnalysis from "@/components/dropdown-analysis";
import VueMultiselect from "@/components/vue-multiselect";
// import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/assets/css/multiselect.scss";
import Pagination from "@/components/pagination";
import axios from "../../../../axios/osint.js";
import {uniqBy} from "lodash";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {setUsernamesDropdownData, convertToCSV, csvFileName} from "../../../../utils/functions";
import JsonCSV from "vue-json-csv";
import toolsDisclaimer from "@/components/tools-disclaimer";
// import nLoader from '@/components/n-loader'

export default {
    name: "neo-username",
    components: {
        "neo-input": Input,
        "neo-button": Button,
        "neo-table": Table,
        "neo-tab": Tab,
        "neo-tabs-switch": NeoTabsSwitch,
        "neo-pagination": Pagination,
        "font-awesome-icon": FontAwesomeIcon,
        "neo-loader": Loader,
        "analysis-card": analysisCard,
        "neo-dropdown-analysis": DropdownAnalysis,
        VueMultiselect,
        "download-csv": JsonCSV,
        toolsDisclaimer,
        // "neo-loader": nLoader
    },
    props: {
        osintGraphDrawerOpen: {
            type: Boolean,
            default: false,
        },
        toolData: {
            type: Array,
            default: () => [],
        },
        tool: {
            type: String,
            default: "",
        },
        toolSearchQueries: Array,
    },
    data() {
        return {
            loaderController: {
                username: {
                    loading: false,
                    success: false,
                    filterLoading: false,
                    filterSuccess: false,
                },
            },
            allUsername: [],
            csvData: {
                username: "",
            },
            alertMessage: {
                success: null,
                error: null,
            },
            case_id: "",
            headers: [
                {
                    text: "",
                    value: "details",
                },
            ],
            itemKey: "url",
            searchCaseFilter: "",
            selectable: false,
            hasAction: false,
            searchKeyword: "",
            userNameTool: [],
            selectedItem: null,
            showResult: false,
            interval: null,
            triggerRoute: true,
            isLoading: false,
            selectVal: "",
            searchedClicked: false,
            request_id: "",
            userNameData: "",
            totalResults: 0,
        };
    },
    computed: {
        ...mapGetters(["getReadOnlyMode", "getResearchData", "getAllUsernameData", "getAllToolsData", "getCaseUsernames"]),

        fetchAllData() {
            if (this.getResearchData.personalDetails?.length || this.getResearchData.socialProfiles?.length) {
                this.getToolData();
            }
        },

        csvFields() {
            return ["name", "username", "category", "generated_uri"];
        },
    },
    watch: {
        // getAllUsernameData() {
        //     if (this.getAllUsernameData?.length > 0) {
        //         this.$emit("searchResult", {
        //             data: this.getAllUsernameData,
        //             source: "username-search",
        //             searchType: "username",
        //         });
        //         // this.resetLoader("username");
        //         this.csvData.username = this.convertToCSV(this.getAllUsernameData);
        //     }
        // },
        "$route.query.q"(to, from) {
            if (this.triggerRoute) {
                this.redirectToQuery();
            }
        },

        // selectedItem() {
        //     this.showResult = false;
        // },
    },
    async mounted() {
        this.case_id = this.$route.params.id;
        const dataRes = this.getAllToolsData.find((n) => n["tool-name"] === "username-tool");
        if (dataRes) {
            Object.entries(dataRes).forEach(([key, value]) => {
                this[key] = value;
            });
            this.redirectToQuery(dataRes);
        }
        this.getToolData();
        if (this.getAllUsernameData?.length > 0) {
            this.$emit("searchResult", {
                data: this.getAllUsernameData,
                source: "username-search",
                searchType: "username",
            });
            this.resetLoader("username");
        }
    },
    beforeDestroy() {
        this.SET_ALL_TOOLS_DATA({...this._data, "tool-name": "username-tool"});
    },
    methods: {
        ...mapActions(["getAllCaseUsernames"]),
        ...mapMutations(["SET_ALL_TOOLS_DATA", "SET_ALL_USERNAME_DATA"]),

        async getToolData() {
            this.userNameTool = await this.getDropdownData("username");
        },

        async getDropdownData(field) {
            let data = this.toolData.filter((user) => user.val_type === field);
            return data;
        },

        getOptionsList(list) {
            return [
                {
                    group: "Username",
                    categories: this[list],
                },
            ];
        },

        handleChecked(data) {
            let allChecked = false;
            this.$emit("updateToolData", "username", [...this.userNameTool], data);
            allChecked = [...this.userNameTool]?.length ? ([...this.userNameTool].findIndex((n) => !n.visited) > -1 ? false : true) : false;
            this.$emit("handleChecked", allChecked);
        },

        convertToCSV(arr) {
            return convertToCSV(arr);
        },

        csvFileName(query, section) {
            return csvFileName(this.tool, query, section);
        },

        startLoader(section) {
            this.loaderController[section].success = false;
            this.loaderController[section].loading = true;
        },
        successLoader(section) {
            this.loaderController[section].success = true;
            this.loaderController[section].loading = false;
        },
        resetLoader(section) {
            this.loaderController[section].success = false;
            this.loaderController[section].loading = false;
        },

        setInput() {
            this.searchKeyword = this.selectedItem.value;
            // this.search();
        },
        setTheCustomInput(value) {
            this.searchKeyword = value;
            this.selectedItem = {
                value: value,
            };
            // this.search();
        },
        async search() {
            this.triggerRoute = false;
            if (this.searchKeyword) {
                this.$emit("searched", {
                    data: {username: this.searchKeyword},
                    source: "username-search",
                });
                // await this.getUsername();
                await this.userToolResults();
                await this.setSearchedSkypeUser(this.searchKeyword);
                this.searchedClicked = true;
                this.triggerRoute = true;
            } else {
                this.$toast.error("Please enter a valid input");
            }
        },
        async setSearchedSkypeUser(searchKeyword) {
            const data = {
                value: searchKeyword,
                platform: "username",
                val_type: this.selectedItem?.val_type || "username",
                visited: true,
            };
            let url = `research-methodology/${this.case_id}/tools/profiles`;
            let isSuccess = await setUsernamesDropdownData(url, data);
            // if (isSuccess) {
            let dataItem = this[`userNameTool`].find((dataItem) => {
                return dataItem.value === searchKeyword;
            });
            if (dataItem) dataItem["visited"] = true;
            else if (data?.value) {
                this[`userNameTool`].push(data);
                this.$emit("addCustomData", data);
            }
            this.handleChecked(data);
            // }
        },
        async userToolResults() {
            this.isLoading = true;
            this.showResult = false;
            // this.SET_ALL_USERNAME_DATA([]);
            clearInterval(this.interval);
            this.startLoader("username");
            // let url = "/username";
            const data = {
                username: this.searchKeyword,
            };
            // await this.fetchUsernameData(data);
            const {data: userToolResults} = await axios.post("/username/search", data, {
                headers: {
                    "x-tool-name": this.$route.params.toolName,
                    "x-visited": true,
                },
            });
            // this.SET_ALL_USERNAME_DATA(userToolResults?.data);
            this.userNameData = userToolResults.data;
            this.totalResults = userToolResults.total;
            this.isLoading = false;
            this.showResult = true;
            this.redirectTo();
            await this.$emit("searchResult", {
                data: userToolResults.data,
                source: "username-search",
                searchType: "username",
            });
        },
        // async getUsername(isNext) {
        //     this.isLoading = true;
        //     this.showResult = false;
        //     this.SET_ALL_USERNAME_DATA([]);
        //     clearInterval(this.interval);
        //     this.startLoader("username");
        //     let data = {};
        //     let url = "/username";
        //     data = {
        //         username: this.searchKeyword,
        //     };
        //     // await this.fetchUsernameData(data);
        //     const { data: token } = await axios.post("/username/token", data, {
        //         headers: {
        //             "x-tool-name": this.$route.params.toolName,
        //             "x-visited": true,
        //         },
        //     });
        //     this.showResult = true;
        //     if (token?.status === "NEW") {
        //         await axios.post("/username/process", { token: token._id });
        //     }

        //     this.interval = setInterval(async () => {
        //         const { data } = await axios.get(`/username/token/${token._id}`);
        //         // commit("SET_ALL_USERNAME_DATA", data?.data);
        //         this.SET_ALL_USERNAME_DATA(data?.data);
        //         if (data.token.status === "DONE") {
        //             this.resetLoader("username");
        //             clearInterval(this.interval);
        //             this.isLoading = false;
        //         }
        //     }, 5000);
        //     this.request_id = token?.query_id;
        //     this.redirectTo();
        //     await this.$emit("searchResult", {
        //         data: this.getAllUsernameData,
        //         source: "username-search",
        //         searchType: "username",
        //     });
        // },

        redirectTo() {
            let query = {};
            if (this.request_id)
                query = {
                    query_id: this.request_id,
                };
            this.$router.push({path: this.$route.path, query}).catch((err) => {});
        },

        redirectToQuery(dataRes) {
            //redirect to same query
            if (this.$route?.query?.query_id) {
                let {query_id} = this.$route.query;
                let selected_query = this.toolSearchQueries?.find((el) => el._id === query_id);

                const value = Object.values(selected_query?.query)[0];

                this.searchKeyword = value;
                this.selectedItem = {
                    value: value,
                };
                this.selectVal = value;
                if (!dataRes) this.search();
            } else {
                this.redirectTo();
            }
        },
        onSelect({value}) {
            this.selectVal = value;
            this.$refs["analysis-tool-input"].search = value;
        },

        close() {
            this.searchKeyword = this.selectVal;
            this.selectedItem = {
                value: this.selectVal,
            };
            this.$refs["analysis-tool-input"].search = this.selectVal;
        },
        handleChange(event) {
            if (event != this.selectVal) {
                this.showResult = false;
                this.searchClicked = false;
            }
            this.selectVal = event;
        },
    },
};

//
//
